import React, { useEffect, useState } from "react";
import Styles from "./home.module.css";
import { toast } from "react-toastify";

const gridData = [
  {
    id: 1,
    imgSrc:
      "https://cdn.zeebiz.com/sites/default/files/2023/09/27/262559-image-1200x900-2023-09-27t055311433.jpg",
    title: "SBI PO/Clerk Coaching Institute",
    info: "Prepare to excel in the SBI PO/Clerk exams with our specialized coaching, offering in-depth training and comprehensive material tailored for success in the banking sector.",
    buttonText: "Enquire Now",
    courseName: "SBI PO/Clerk Coaching Institute",
  },
  {
    id: 2,
    imgSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt-e9GnnzvlsHNQiID4aSqfRSUWLRv9oO75g&s",
    title: "IBPS Institutes",
    info: "Our IBPS coaching is designed to equip you with the knowledge and skills needed to crack the exams, focusing on key concepts and practice for all IBPS exams.",
    buttonText: "Enquire Now",
    courseName: "IBPS Institutes",
  },
  {
    id: 3,
    imgSrc:
      "https://image3.jdomni.in/library/4F/E5/4F/55E671B4EBEA88BD4B564DFA1B_1496271913054_cropped_450X450.jpeg",
    title: "Bank Exams Tutorials",
    info: "Join our Bank Exams Tutorials to gain the competitive edge with expert guidance, covering all major banking exams, from basics to advanced strategies.",
    buttonText: "Enquire Now",
    courseName: "Bank Exams Tutorials",
  },
  {
    id: 4,
    imgSrc:
      "https://image3.jdomni.in/library/23/D0/4D/34A6957999A6D4F1AB43E9D78B_1496265437490_cropped_450X450.jpeg",
    title: "Staff Selection Commission Tutorials",
    info: "Our SSC tutorials provide targeted preparation for all SSC exams, focusing on quantitative aptitude, reasoning, and general awareness to ensure your success.",
    buttonText: "Enquire Now",
    courseName: "Staff Selection Commission Tutorials",
  },
  {
    id: 5,
    imgSrc:
      "https://image2.jdomni.in/banner/21042024/91/77/A0/2AD501B4C954E242057A48EEE5_1713675677788.jpg?output-format=webp",
    title: "IBPS Bank Exams Tutorials",
    info: "Master the IBPS Bank Exams with our specialized tutorials, offering focused preparation on all sections, including English, Quant, and Reasoning.",
    buttonText: "Enquire Now",
    courseName: "IBPS Bank Exams Tutorials",
  },
  {
    id: 6,
    imgSrc:
      "https://images.unsplash.com/photo-1450101499163-c8848c66ca85?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Insurance Exams Tutorials",
    info: "Our Insurance Exams Tutorials offer expert coaching on all key topics, ensuring you are well-prepared to clear exams like LIC AAO and others in the insurance sector.",
    buttonText: "Enquire Now",
    courseName: "Insurance Exams Tutorials",
  },
  {
    id: 7,
    imgSrc: "https://www.rrbcdg.gov.in/images/logo.png",
    title: "RRB Exams Coaching Institute",
    info: "With our RRB coaching, we provide in-depth coverage of the syllabus for Railway Recruitment Board exams, helping you secure a government job in the railway sector.",
    buttonText: "Enquire Now",
    courseName: "RRB Exams Coaching Institute",
  },
  {
    id: 8,
    imgSrc:
      "https://i.pinimg.com/originals/12/a5/59/12a5596b6cdd063713cfe0c298fd832e.png",
    title: "RBI Exams Coaching Institute",
    info: "Prepare for RBI exams with our specialized coaching, offering thorough training on all aspects of the exam, from economics to general awareness and more.",
    buttonText: "Enquire Now",
    courseName: "RBI Exams Coaching Institute",
  },
];

const Home = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    mobile: false,
    message: false,
  });

  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear errors on input change
    setFormErrors({ ...formErrors, [name]: false });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, mobile, message } = formData;

    // Basic validation
    if (name === "") setFormErrors((prev) => ({ ...prev, name: true }));
    if (mobile === "") setFormErrors((prev) => ({ ...prev, mobile: true }));
    if (message === "") setFormErrors((prev) => ({ ...prev, message: true }));

    // If no errors, process the form (e.g., send data to server or display success message)
    if (name && mobile && message) {
      console.log("Form submitted successfully", formData);
      alert("Form submitted successfully");
      toast.success("Form submitted successfully", {
        position: "bottom-right",
      });
      // Reset form
      setFormData({
        name: "",
        email: "",
        mobile: "",
        message: "",
      });
    }
  };

  // move to top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
    setTimeout(() => {
      setIsPageLoaded(true);
    }, 50);
  }, []);
  
  const handleEnquiryClick = (courseName) => {
    const phoneNumber = "+919225732248";
    const message = `Hello, I have to enquire about ${courseName}, please guide me on this.`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    // Copy text to clipboard
    navigator.clipboard.writeText(message).then(
      () => {
        console.log("Text copied to clipboard");
        // Redirect to WhatsApp after copying the text
        window.open(whatsappUrl, "_blank");
      },
      (err) => {
        console.error("Failed to copy text: ", err);
        // Redirect to WhatsApp even if copying fails
        window.open(whatsappUrl, "_blank");
      }
    );
  };
  return (
    <div
      className={`${Styles.container} ${
        isPageLoaded ? Styles.containerLoaded : ""
      }`}
    >
      <div
        className="h-[90vh] m-0 p-0 flex justify-center items-center"
        style={{
          background: `linear-gradient(to bottom, rgba(51, 51, 51, 0.8), rgba(51, 51, 51, 0.8)), url("https://cdn.ajnavidya.com/ajnavidya/BAFrontImage_1723295819240.jpg") center/cover no-repeat`,
        }}
      >
        <h1 className="text-[#f8f9fa] text-[24px] lg:text-[62px] text-center font-semibold">
          Celebrating 25 Years of Excellence: <br /> Empowering Futures at
          Borde's Career Academy
        </h1>
      </div>

      {/* Grid */}

      <div className="p-10">
        <h1 className="text-3xl text-[#3c3e95]  my-[4rem]">Courses</h1>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          {gridData.map((item, index) => (
            <div
              key={index}
              className="border rounded-2xl overflow-hidden flex flex-col h-full"
            >
              <img
                className="w-full h-[200px] md:h-[300px] object-cover"
                src={item.imgSrc}
                alt={item.title}
              />
              <div className="flex flex-col justify-between p-4 flex-1">
                <div className="flex-1">
                  <h1 className="text-2xl">{item.title}</h1>
                  <p className="text-[#666] mt-2 overflow-hidden max-h-[4.5em] md:max-h-[6em] text-ellipsis">
                    {item.info}
                  </p>
                </div>
                <button
                  className="text-[#f8f9fa] mt-4 rounded-full px-4 py-2 bg-[#e23038] transform transition-all duration-300 hover:scale-110 hover:bg-[#f18c8e]"
                  onClick={() => handleEnquiryClick(item.courseName)}
                >
                  {item.buttonText}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Contact us section */}

      <div className="my-[4rem]">
        <p className="text-[#3c3e95] text-3xl text-center mb-[2rem]">
          Contact Us
        </p>
        <div className="flex justify-center items-center w-full">
          <form
            className="bg-[#f8f9fa] px-8 py-6 rounded-lg  max-w-4xl w-full"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col md:flex-row gap-4 mb-4">
              <div className="w-full md:w-1/2">
                <label className="block text-[#3c3e95] text-left font-semibold mb-2">
                  Name<span className="text-red-500">*</span>
                </label>
                <input
                  className={`w-full p-2 border rounded-lg ${
                    formErrors.name ? "border-red-500" : "border-gray-300"
                  }`}
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  value={formData.name}
                  maxLength={100}
                  onChange={handleChange}
                />
                {formErrors.name && (
                  <p className="text-red-500 text-sm text-left">
                    Name is required.
                  </p>
                )}
              </div>

              <div className="w-full md:w-1/2">
                <label className="block text-[#3c3e95] text-left font-semibold mb-2">
                  Email
                </label>
                <input
                  className="w-full p-2 border rounded-lg border-gray-300"
                  type="email"
                  name="email"
                  placeholder="Email ID"
                  maxLength={80}
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Row 2: Mobile Number and Message */}
            <div className="flex flex-col md:flex-row gap-4 mb-4">
              <div className="w-full md:w-1/2">
                <label className="block text-[#3c3e95] text-left font-semibold mb-2">
                  Mobile Number<span className="text-red-500">*</span>
                </label>
                <input
                  className={`w-full p-2 border rounded-lg ${
                    formErrors.mobile ? "border-red-500" : "border-gray-300"
                  }`}
                  type="text"
                  name="mobile"
                  placeholder="Mobile Number"
                  value={formData.mobile}
                  onChange={handleChange}
                  maxLength={13}
                />
                {formErrors.mobile && (
                  <p className="text-red-500 text-sm text-left">
                    Mobile number is required.
                  </p>
                )}
              </div>

              <div className="w-full md:w-1/2">
                <label className="block text-[#3c3e95] text-left font-semibold mb-2">
                  Message<span className="text-red-500">*</span>
                </label>
                <textarea
                  className={`w-full h-10 p-2 border rounded-lg ${
                    formErrors.message ? "border-red-500" : "border-gray-300"
                  }`}
                  style={{ resize: "none" }}
                  name="message"
                  placeholder="Enter Your Message"
                  value={formData.message}
                  onChange={handleChange}
                />
                {formErrors.message && (
                  <p className="text-red-500 text-sm text-left">
                    Message is required.
                  </p>
                )}
              </div>
            </div>

            <button
              type="submit"
              className="w-fit-content px-4 bg-[#e23038] text-[#f8f9fa] p-2 rounded-full font-semibold transform transition-all duration-300 hover:scale-105 hover:bg-[#f18c8e] my-2"
            >
              Submit Now
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Home;
