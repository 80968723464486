import React from "react";
import { Routes, Route } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import Home from "./components/home/Home";
import Navbar from "./components/navbar/Navbar";
import About from "./components/about/About"; // Example additional page
import Contact from "./components/contact/Contact"; // Example additional page
import Topbar from "./components/topbar/Topbar";
import WhyUs from "./components/why-us/WhyUs";
import Footer from "./components/footer/Footer";
import SuccessStories from "./components/success/SuccessStories";
import Testimonials from "./components/testimonials/Testimonials";

function App() {
  return (
    <div className="App">
      {/* <Topbar /> */}
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/why-us" element={<WhyUs />} />
        <Route path="/success-stories" element={<SuccessStories />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
      {/* WhatsApp Icon */}~
      <a
        href="https://wa.me/+919225732248"
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-5 right-5 h-16 w-16 flex items-center justify-center bg-green-500 text-white rounded-full shadow-lg"
        style={{ fontSize: "42px" }}
      >
        <i className="fab fa-whatsapp "></i>
      </a>
    </div>
  );
}

export default App;
