import Anubhav_M from "./assets/studentImages/Student1.png";
import Arundhati_Adhave from "./assets/studentImages/Arundhati_A.png";
import Anjali_Jadhav from "./assets/studentImages/Anjali Jadhav.png";
import Akash_Chaware from "./assets/studentImages/Akash Chaware.png";
import Chetan_Jagtap from "./assets/studentImages/Chetan Jagtabp.png"; //Chetan Jagtap
import Girish_Bagulwar from "./assets/studentImages/Girish Bagulwar.png";
import Vishal_Gorde from "./assets/studentImages/Vishal Gorde.png";
import Anant_Shevlikar from "./assets/studentImages/Anant Shevlikar.png";
import Asmita_Virshid from "./assets/studentImages/Asmita Virshid (Borde).png";
import Bhagyashgree_Thakur from "./assets/studentImages/Bhagyashree Thakur.png";
import Shital_Bongane from "./assets/studentImages/Shital Bongane.png";
import Yogeshri_Nande from "./assets/studentImages/Yogeshri Nande.png";
import Pranoti_Lokahnde from "./assets/studentImages/Pranoti Lokahnde.png";
import Sanjivani_Dhanrai from "./assets/studentImages/Sanjivani Dhanraj.png";
import Sneha_Hemadre from "./assets/studentImages/Sneha Hemadre.png";
import Shweta_Shende from "./assets/studentImages/Shweta Shende.png";
import Shilpa_Uigare from "./assets/studentImages/Shilpa Ujgare.png";
import Prutha_Luley from "./assets/studentImages/Prutha Luley.png";
import Priyanka_Boldhane from "./assets/studentImages/Priyanka Boldhane.png";
import Komal_Jaiswal from "./assets/studentImages/Komal Ja.png";
import Namrata_Watode from "./assets/studentImages/Namrata W.png";
import Pratiksha_Jagat from "./assets/studentImages/Pratiksha Jagat.png";
import Kshitija_Bora from "./assets/studentImages/Kshitija Bora.png";
import Mayuri_Deoka from "./assets/studentImages/Mayuri Deokar.png";
import Ganesh_Umraikar from "./assets/studentImages/Ganesh U.png";
import Amol_Kulkarni from "./assets/studentImages/Amol K.png";
import Govind_Kumbhakarn from "./assets/studentImages/Govind K.png";
import Anurag_Pagare from "./assets/studentImages/Anurag P.png";
import Namrata_Chandaliya from "./assets/studentImages/Namrata C.png";
import Harshal_Gajbhiye from "./assets/studentImages/Harshal G.png";
import Mayur_Gavande from "./assets/studentImages/Mayur G.png";
import Shrikant_Pekamwar from "./assets/studentImages/Shirikant P.png";
import Dinesh_Patil from "./assets/studentImages/Dinesh P.png";
import Nitesh_Jadhav from "./assets/studentImages/Nitesh J.png";
import Vikas_Khole from "./assets/studentImages/Vikas K.png";
import Pranav_Kharwadkar from "./assets/studentImages/Pranav K..png";
import Manoj_Wangal from "./assets/studentImages/Manoj W..png";
import Devesh_Vaidya from "./assets/studentImages/Devesh V..png";

export const StudentData = [
  {
    id: 1,
    name: "Anubhav Mishra",
    imgUrl: Anubhav_M,
    position: "PO - Bank of Baroda",
  },
  {
    id: 2,
    name: "Arundhati Adhave",
    imgUrl: Arundhati_Adhave,
    position: "PO - Maha. Gramin Bank",
  },
  {
    id: 3,
    name: "Anjali Jadhav",
    imgUrl: Anjali_Jadhav,
    position: "PO - Bank of Baroda",
  },
  {
    id: 4,
    name: "Akash Chaware",
    imgUrl: Akash_Chaware,
    position: "PO - Maha. Gramin Bank",
  },
  {
    id: 5,
    name: "Chetan Jagtap",
    imgUrl: Chetan_Jagtap,
    position: "PO - Punjab & Sindh Bank",
  },
  {
    id: 6,
    name: "Girish Bagulwar",
    imgUrl: Girish_Bagulwar,
    position: "PO - Union Bank",
  },
  {
    id: 7,
    name: "Vishal Gorde",
    imgUrl: Vishal_Gorde,
    position: "PO - Bank of Baroda",
  },
  {
    id: 8,
    name: "Anant Shevlikar",
    imgUrl: Anant_Shevlikar,
    position: "PO - Bank of Baroda",
  },
  {
    id: 9,
    name: "Asmita Virshid (Borde)",
    imgUrl: Asmita_Virshid,
    position: "Officer CIDCO",
  },
  {
    id: 10,
    name: "Bhagyashgree Thakur",
    imgUrl: Bhagyashgree_Thakur,
    position: "PO - Bank of India",
  },
  {
    id: 11,
    name: "Shital Bongane",
    imgUrl: Shital_Bongane,
    position: "PO - Maha. Gramin Bank",
  },
  {
    id: 12,
    name: "Yogeshri Nande",
    imgUrl: Yogeshri_Nande,
    position: "PO - State Bank of India",
  },
  {
    id: 13,
    name: "Praniti Lokahnde",
    imgUrl: Pranoti_Lokahnde,
    position: "Bank of India",
  },
  {
    id: 14,
    name: "Sanjivani Dhanrai",
    imgUrl: Sanjivani_Dhanrai,
    position: "PO - Union Bank of India",
  },
  {
    id: 15,
    name: "Sneha Hemadre",
    imgUrl: Sneha_Hemadre,
    position: "PO - Union Bank of India",
  },
  {
    id: 16,
    name: "Shweta Shende",
    imgUrl: Shweta_Shende,
    position: "Punjab National Bark",
  },
  {
    id: 17,
    name: "Shilpa Ujagare",
    imgUrl: Shilpa_Uigare,
    position: "NIACL",
  },
  {
    id: 18,
    name: "Prutha Luley",
    imgUrl: Prutha_Luley,
    position: "PO - Bank of India",
  },
  {
    id: 19,
    name: "Priyanka Boldhane",
    imgUrl: Priyanka_Boldhane,
    position: "Bank of Baroda",
  },
  {
    id: 20,
    name: "Komal Jaiswal",
    imgUrl: Komal_Jaiswal,
    position: "Canara Bank",
  },
  {
    id: 21,
    name: "Namrata Watode",
    imgUrl: Namrata_Watode,
    position: "MSEB Account Officer",
  },
  {
    id: 22,
    name: "Pratiksha Jagat",
    imgUrl: Pratiksha_Jagat,
    position: "PO - Axis Bank",
  },
  {
    id: 23,
    name: "Kshitija Bora",
    imgUrl: Kshitija_Bora,
    position: "State Govt. - MSEB",
  },
  {
    id: 24,
    name: "Mayuri Deokar",
    imgUrl: Mayuri_Deoka,
    position: "PO- ICICI Bank",
  },
  {
    id: 25,
    name: "Ganesh Umraikar",
    imgUrl: Ganesh_Umraikar,
    position: "PO - Bank of India",
  },
  {
    id: 26,
    name: "Amol Kulkarni",
    imgUrl: Amol_Kulkarni,
    position: "PO - Mahe Gramin Bank",
  },
  {
    id: 27,
    name: "Govind Kumbhakarn",
    imgUrl: Govind_Kumbhakarn,
    position: "PO - State Bank of India",
  },
  {
    id: 28,
    name: "Anurag Pagare",
    imgUrl: Anurag_Pagare,
    position: "PO- IDBI Bank",
  },
  {
    id: 29,
    name: "Namrata Chandaliya",
    imgUrl: Namrata_Chandaliya,
    position: "MSEB Account Officer",
  },
  {
    id: 30,
    name: "Harshal Gajbhiye",
    imgUrl: Harshal_Gajbhiye,
    position: "Madhva Bihar gramin bank",
  },
  {
    id: 31,
    name: "Mayur Gavande",
    imgUrl: Mayur_Gavande,
    position: "PO - Bank of India",
  },
  {
    id: 32,
    name: "Shrikant Pekamwar",
    imgUrl: Shrikant_Pekamwar,
    position: "PO . UCO Bark",
  },
  {
    id: 33,
    name: "Dinesh Patil",
    imgUrl: Dinesh_Patil,
    position: "Asst. Mangr. - Bandhan Bank",
  },
  {
    id: 34,
    name: "Nitesh Jadhav",
    imgUrl: Nitesh_Jadhav,
    position: "PO - SVC Bank",
  },
  {
    id: 35,
    name: "Vikas Khole",
    imgUrl: Vikas_Khole,
    position: "UC ADO",
  },
];
