import React, { useEffect } from "react";
import styles from "./testimonial.module.css";
import Student1 from "../../assets/testimonials/TSP1.png";
import Student2 from "../../assets/testimonials/TSP2.png";
import Student3 from "../../assets/testimonials/TSP3.png";
import Student4 from "../../assets/testimonials/TSP4.png";
import Student5 from "../../assets/testimonials/TSP5.png";
import Student6 from "../../assets/testimonials/TSP6.png";

const testimonialsData = [
  {
    id: 1,
    image: Student1,
    name: "Priyanka Gowardhan",
    post: "PO - State Bank Of India",
    text: `"The institute that gave me designation as SBI PO. Very unique way of teaching. So that everyone in the class understands the topic clearly. Sir also provided guidance regarding time management during exams which turned out to be very useful. Along with written exams, sir also prepared me for the interview. Thank you!"`,
  },
  {
    id: 2,
    image: Student2,
    name: "Anil Satpote",
    post: "Asst - Currency Note Press",
    text: `"क्लासमध्ये मिळालेल्या मार्गदर्शन, छोटे छोटे नुस्के व अनेक यशाकडे वाटचाल करण्यासाठी उपयोगात पडलेल्या पद्धती, तसेच बोर्डे सरांचे प्रत्येक टप्प्यावर काटेकोर लक्ष या सर्वच्या बळावर मी आज इथपर्यंत पोहोचलो आहे असं मला वाटतं. क्लासच्या यशाची वाटचाल अशीच अखंड चालू राहो अशी प्रार्थना करतो. धन्यवाद!"`,
  },
  {
    id: 3,
    image: Student3,
    name: "Aniket Dabshede",
    post: "PO - Bank of Baroda",
    text: `"I am extremely thankful to Borde's Career Academy and Borde Sir for support and guidance. I used to call Borde Sir even at midnight sometimes for guidance & motivation. But sir never refused or avoided helping me out with my problems. Thank you!"`,
  },
  {
    id: 4,
    name: "Amol Mandlik",
    post: "PO - Maha. Gramin Bank",
    image: Student4,
    text: `"I would like to thank BordeSir blessing me with his valuable guidance & support and making me taste success. He is wonderful motivator and have great capabilities of filling courage and confidence in the hearts of the students. Thank you!"`,
  },
  {
    id: 5,
    name: "Sachin Shinde",
    post: "New India Assurance",
    image: Student5,
    text: `"क्लासमध्ये मिळालेल्या मार्गदर्शन, छोटे छोटे नुस्के व अनेक यशाकडे वाटचाल करण्यासाठी उपयोगात पडलेल्या पद्धती, तसेच बोर्डे सरांचे प्रत्येक टप्प्यावर काटेकोर लक्ष या सर्वच्या बळावर मी आज इथपर्यंत पोहोचलो आहे असं मला वाटतं. क्लासच्या यशाची वाटचाल अशीच अखंड चालू राहो अशी प्रार्थना करतो. धन्यवाद!"`,
  },
  {
    id: 6,
    name: "Priyanka Kedare",
    post: "Inspector - Central Excise",
    image: Student6,
    text: `"Am grateful to Borde Sir for his precious guidance which I could not find anywhere
Borde's Career Academy is more efficient than any other institutes involved in Banking & SSC exams preparations.
Motivational speeches by sir helped to study hard & sincerely. Thank you!"`,
  },
];

const Testimonials = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.titleBox}>
        <p className="text-2xl md:text-4xl font-semibold mb-2 md:mb-4">
          Success Stories
        </p>
        <p className="text-[14px] md:text-[22px]">
          Every story is a step towards success. Here's how it happened
        </p>
      </div>

      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-10 md:px-[8rem]">
        {testimonialsData.map((testimonial) => (
          <div
            key={testimonial.id}
            className="flex flex-col md:flex-row justify-evenly items-center border border-[#3c3e95] rounded-lg gap-4 p-2"
          >
            <div className="p-8 pb-0 md:p-0 md:w-[250px]">
              <img
                className="aspect-square  rounded-lg"
                src={testimonial.image}
                alt="Testimonial"
              />

              <p className="text-lg font-semibold mt-2 text-[#3c3e95]">
                {testimonial?.name}
              </p>
              <p className="text-md text-[#3c3e95]">{testimonial?.post}</p>
            </div>
            <div className="md:w-1/2">
              <p className="text-md md:text-lg text-start text-[#3c3e95]">
                {testimonial.text}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
