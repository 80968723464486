import React from "react";
import styles from "./footer.module.css";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div
        className="flex gap-2 items-center"
        onClick={() => window.open("https://eduthor.page.link/QpEk", "_blank")}
        style={{ cursor: "pointer" }}
      >
        <svg height={30} width={30} viewBox="0 0 512 512">
          <g>
            <path
              style={{ fill: "#32BBFF" }}
              d="M382.369,175.623C322.891,142.356,227.427,88.937,79.355,6.028 C69.372-0.565,57.886-1.429,47.962,1.93l254.05,254.05L382.369,175.623z"
            />
            <path
              style={{ fill: "#32BBFF" }}
              d="M47.962,1.93c-1.86,0.63-3.67,1.39-5.401,2.308C31.602,10.166,23.549,21.573,23.549,36v439.96 c0,14.427,8.052,25.834,19.012,31.761c1.728,0.917,3.537,1.68,5.395,2.314L302.012,255.98L47.962,1.93z"
            />
            <path
              style={{ fill: "#32BBFF" }}
              d="M302.012,255.98L47.956,510.035c9.927,3.384,21.413,2.586,31.399-4.103 c143.598-80.41,237.986-133.196,298.152-166.746c1.675-0.941,3.316-1.861,4.938-2.772L302.012,255.98z"
            />
          </g>
          <path
            style={{ fill: "#2C9FD9" }}
            d="M23.549,255.98v219.98c0,14.427,8.052,25.834,19.012,31.761c1.728,0.917,3.537,1.68,5.395,2.314 L302.012,255.98H23.549z"
          />
          <path
            style={{ fill: "#29CC5E" }}
            d="M79.355,6.028C67.5-1.8,53.52-1.577,42.561,4.239l255.595,255.596l84.212-84.212 C322.891,142.356,227.427,88.937,79.355,6.028z"
          />
          <path
            style={{ fill: "#D93F21" }}
            d="M298.158,252.126L42.561,507.721c10.96,5.815,24.939,6.151,36.794-1.789 c143.598-80.41,237.986-133.196,298.152-166.746c1.675-0.941,3.316-1.861,4.938-2.772L298.158,252.126z"
          />
          <path
            style={{ fill: "#FFD500" }}
            d="M488.45,255.98c0-12.19-6.151-24.492-18.342-31.314c0,0-22.799-12.721-92.682-51.809l-83.123,83.123 l83.204,83.205c69.116-38.807,92.6-51.892,92.6-51.892C482.299,280.472,488.45,268.17,488.45,255.98z"
          />
          <path
            style={{ fill: "#FFAA00" }}
            d="M470.108,287.294c12.191-6.822,18.342-19.124,18.342-31.314H294.303l83.204,83.205 C446.624,300.379,470.108,287.294,470.108,287.294z"
          />
        </svg>
        <p className="text-xl font-bold text-[#3c3e95]">Download The App Now</p>
      </div>

      <div className="flex flex-col lg:flex-row gap-4 items-center">
        <p className="text-xl font-bold text-[#3c3e95]">
          Follow Us for Updates :
        </p>

        <div className="flex gap-4 items-center">
          {/* telegram */}

          <svg
            onClick={() =>
              window.open(
                "https://t.me/BordesCareerAcademyAurangabad",
                "_blank"
              )
            }
            className={styles.icon}
            viewBox="0 0 32 30"
            fill="none"
            style={{ cursor: "pointer" }}
          >
            <circle cx="16" cy="16" r="14" fill="url(#paint0_linear_87_7225)" />
            <path
              d="M22.9866 10.2088C23.1112 9.40332 22.3454 8.76755 21.6292 9.082L7.36482 15.3448C6.85123 15.5703 6.8888 16.3483 7.42147 16.5179L10.3631 17.4547C10.9246 17.6335 11.5325 17.541 12.0228 17.2023L18.655 12.6203C18.855 12.4821 19.073 12.7665 18.9021 12.9426L14.1281 17.8646C13.665 18.3421 13.7569 19.1512 14.314 19.5005L19.659 22.8523C20.2585 23.2282 21.0297 22.8506 21.1418 22.1261L22.9866 10.2088Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear_87_7225"
                x1="16"
                y1="2"
                x2="16"
                y2="30"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#37BBFE" />
                <stop offset="1" stopColor="#007DBB" />
              </linearGradient>
            </defs>
          </svg>

          {/* youtube */}
          <svg
            className={styles.icon}
            viewBox="0 -8 48 48"
            onClick={() =>
              window.open(
                "https://youtube.com/@bordescareeracademy6403?si=Xu26UHMUMO-zGjLl",
                "_blank"
              )
            }
            style={{ cursor: "pointer" }}
          >
            <g fill="#CE1312">
              <path
                d="M219.044,391.269916 L219.0425,377.687742 L232.0115,384.502244 L219.044,391.269916 Z M247.52,375.334163 C247.52,375.334163 247.0505,372.003199 245.612,370.536366 C243.7865,368.610299 241.7405,368.601235 240.803,368.489448 C234.086,368 224.0105,368 224.0105,368 L223.9895,368 C223.9895,368 213.914,368 207.197,368.489448 C206.258,368.601235 204.2135,368.610299 202.3865,370.536366 C200.948,372.003199 200.48,375.334163 200.48,375.334163 C200.48,375.334163 200,379.246723 200,383.157773 L200,386.82561 C200,390.73817 200.48,394.64922 200.48,394.64922 C200.48,394.64922 200.948,397.980184 202.3865,399.447016 C204.2135,401.373084 206.612,401.312658 207.68,401.513574 C211.52,401.885191 224,402 224,402 C224,402 234.086,401.984894 240.803,401.495446 C241.7405,401.382148 243.7865,401.373084 245.612,399.447016 C247.0505,397.980184 247.52,394.64922 247.52,394.64922 C247.52,394.64922 248,390.73817 248,386.82561 L248,383.157773 C248,379.246723 247.52,375.334163 247.52,375.334163 Z"
                transform="translate(-200.000000, -368.000000)"
              ></path>
            </g>
          </svg>

          {/* playstore */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
