import React, { useEffect } from "react";
import styles from "./success.module.css";
import { StudentData } from "../../data";

const SuccessStories = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.titleBox}>
        <p className="text-2xl md:text-4xl font-semibold mb-2 md:mb-4">
          Recent Selections
        </p>
        <p className="text-[14px] md:text-[22px]">
          We have an excellent track record with the Results
        </p>
      </div>

      <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 md:px-[8rem]">
        {StudentData.map((person, index) => (
          <div key={index}>
            <img
              src={person.imgUrl}
              className="rounded-lg aspect-square md:h-[200px] w-full object-fit overflow-hidden"
              alt={person.name}
            />
            <div className="my-2">
              <p className="text-md md:text-xl font-semibold text-[#3c3e95]">
                {person.name}
              </p>
              <p className="text-sm md:text-md font-semibold">
                {person.position}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuccessStories;
