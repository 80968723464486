import React from "react";

const EmailIcon = ({ w = "24", h = "24" }) => {
  return (
    <div>
      <svg
        width={w}
        height={h}
        viewBox="0 -2.5 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Dribbble-Light-Preview"
            transform="translate(-340.000000, -922.000000)"
            fill="#3c3e95" // Updated color
          >
            <g id="icons" transform="translate(56.000000, 160.000000)">
              <path
                d="M294,774.474 L284,765.649 L284,777 L304,777 L304,765.649 L294,774.474 Z M294.001,771.812 L284,762.981 L284,762 L304,762 L304,762.981 L294.001,771.812 Z"
                id="email-[#1572]"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default EmailIcon;
