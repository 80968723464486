import React, { useEffect } from "react";
import Bordesir from "../../assets/BoradeSirPicturenew.jpg";

const About = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="flex flex-col items-center py-4">
      <p className=" text-[2rem] font-bold text-center text-[#3c3e95]  md:my-4">
        About Us
      </p>

      <div className="flex flex-col md:flex-row items-stretch w-full max-w-5xl px-4">
        {/* Text Section */}
        <div className="md:w-1/2 p-4 flex flex-col justify-between">
          <p className="text-justify text-[1.25rem] text-[#3c3e95]">
            Since 1999, Borde's Career Academy has been known as a key to
            success and achievements for the preparation of IBPS Bank PO, Clerk,
            SSC & UPSC exams. Our continuous striving efforts in helping
            hundreds of students prosper in their respective chosen fields make
            us the most preferred coaching institute in the field of Banking and
            many other branches of education. Borde's is known for its
            commitment to providing quality education & guidelines for its
            students with a view of making them capable enough to tackle their
            competitors in different competitive & entrance exams. Here at
            Borde's, we provide necessary study materials and conduct demo tests
            for the development of our students.
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-1/2 p-4 flex flex-col justify-center items-center">
          <img
            src={Bordesir}
            alt="Borde's Career Academy"
            className="w-full max-w-[400px] h-auto rounded-xl"
          />
          <div className="text-center mt-4">
            <p className="text-xl font-semibold text-[#3c3e95] uppercase">
              Prof. Santosh Borde
            </p>
            <p className="text-md text-[#666]">Director</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
