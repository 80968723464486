import React, { useEffect } from "react";
import Location from "./Location";
import PhoneCall from "./PhoneCall";
import EmailIcon from "./EmailIcon";
import TimeIcon from "./TimeIcon";

const Contact = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleGetDirections = () => {
    // URL to open Google Maps with directions to your location
    const directionsUrl =
      "https://www.google.com/maps/dir/?api=1&destination=Borde's%20Career%20Academy";
    window.open(directionsUrl, "_blank");
  };

  return (
    <div className="px-4 md:px-8">
      <p className="text-[1.5rem] md:text-[2rem] font-bold text-center text-[#3c3e95] my-2 mt-4">
        Contact Us
      </p>
      <div className="flex flex-col md:flex-row justify-center my-[2rem] gap-10 lg:gap-4">
        <div className="relative w-full md:w-1/2">
          {/* Map */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3752.284290266954!2d75.32626507468453!3d19.87021402656109!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdb9865455be245%3A0x5f8c413f26a7fd13!2sBorde&#39;s%20Career%20Academy!5e0!3m2!1sen!2sin!4v1723373611366!5m2!1sen!2sin"
            width="100%"
            height="450"
            className="w-full h-full md:h-[450px] rounded-lg"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>

          {/* Custom Button */}
          <button
            onClick={handleGetDirections}
            className="absolute top-4 right-4 bg-[#e23038] text-white px-4 py-2 rounded-full font-semibold shadow-lg transform transition-all duration-300 hover:scale-105 hover:bg-[#f18c8e]"
          >
            Get Directions
          </button>
        </div>
        {/* right section */}
        <div className="w-full md:w-1/2 flex flex-col justify-center px-auto  ">
          <div className="flex flex-col justify-center items-center gap-6 ">
            {/* location */}
            <div className="flex gap-4 items-start">
              <div className="p-2 md:p-4">
                <Location w="32px" h="32px" mdw="42px" mdh="42px" />
              </div>
              <div className="w-[250px] md:w-[30rem]">
                <p className="text-xl md:text-2xl text-left text-[#3c3e95]">
                  Our Office Address
                </p>
                <p className="text-[#666] text-left text-base md:text-lg">
                  Besides Sant Eknath Rang Mandir, Osmanpura, <br />
                  Chhatrapati Sambhaji Nagar, Maharashtra 431005
                </p>
              </div>
            </div>

            {/* Phone */}
            <div className="flex gap-4 items-start">
              <div className="p-2 md:p-4">
                <PhoneCall w="32px" h="32px" mdw="42px" mdh="42px" />
              </div>
              <div className="w-[250px] md:w-[30rem]">
                <p className="text-xl md:text-2xl text-left text-[#3c3e95]">
                  Call Us
                </p>
                <p className="text-[#666] text-left text-base md:text-lg">
                  Mobile No : +91-9881062048
                </p>
              </div>
            </div>
            {/* Email */}
            <div className="flex gap-4 items-start">
              <div className="p-2 md:p-4">
                <EmailIcon w="32px" h="32px" mdw="42px" mdh="42px" />
              </div>
              <div className="w-[250px] md:w-[30rem]">
                <p className="text-xl md:text-2xl text-left text-[#3c3e95]">
                  General Enquiries
                </p>
                <p className="text-[#666] text-left text-base md:text-lg">
                  bordecareeracademy@rediffmail.com
                </p>
              </div>
            </div>

            {/* timings */}
            <div className="flex gap-4 items-start">
              <div className="p-2 md:p-4">
                <TimeIcon w="32px" h="32px" mdw="42px" mdh="42px" />
              </div>
              <div className="w-[250px] md:w-[30rem]">
                <p className="text-xl md:text-2xl text-left text-[#3c3e95]">
                  Our Timing
                </p>
                <p className="text-[#666] text-left text-base md:text-lg">
                  Mon - Sat : 07:30 AM - 08:00 PM <br /> Sun : Closed
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
