import React, { useEffect } from "react";
import Bordesir from "../../assets/BoradeSirPicturenew.jpg";
import AnilSir from "../../assets/team/AnilSir.jpg";
import PrashantSir from "../../assets/team/PrashantSir.jpg";
import VishalSir from "../../assets/team/VishalSir.jpg";

const teamData = [
  {
    name: "Santosh Sir",
    expertise: ["Quantitative Aptitude", "General Intelligence"],
    image: Bordesir, // Replace with actual image path
  },
  // {
  //   name: "Sachin Sir",
  //   expertise: ["Current Affairs"],
  //   image: Bordesir, // Replace with actual image path
  // },
  {
    name: "Anil Sir",
    expertise: ["English"],
    image: AnilSir, // Replace with actual image path
  },
  {
    name: "Prashant Sir",
    expertise: ["Logical Reasoning"],
    image: PrashantSir, // Replace with actual image path
  },
  {
    name: "Vishal Sir",
    expertise: ["Quantitative Aptitude", "General Intelligence"],
    image: VishalSir, // Replace with actual image path
  },
  // {
  //   name: "Mayur Sir",
  //   expertise: ["General Science & Technology"],
  //   image: Bordesir, // Replace with actual image path
  // },
  // {
  //   name: "Kailash Sir",
  //   expertise: ["General Studies"],
  //   image: Bordesir, // Replace with actual image path
  // },
];

const WhyUs = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div>
      <div className="flex flex-col items-center py-4">
        <p className="text-[2rem] font-bold text-center text-[#3c3e95] md:my-4">
          Why Us
        </p>

        <div className="flex flex-col md:flex-row items-stretch w-full max-w-5xl px-4">
          <div className=" p-4 flex flex-col justify-between">
            <p className="text-justify text-[1.25rem] text-[#3c3e95]">
              As said before, Borde's continous striving efforts in helping
              hundreds of students prosper in their respective chosen fields
              makes them the most preferred coaching institute in field of IBPS
              Bank PO, Clerk, SSC & UPSC exams. We believe in the values of
              Hardwork, Dedication, Determination, Passion, Perfection &
              Excellence in true sense. We are known for clearing the issues of
              our students and helping them achieve their milestones in simple,
              easy and effective ways. Also, we keep on boosting and motivating
              our students to keep them focused always towards
              their desired goals.
            </p>
          </div>
        </div>

        <div className=" w-full max-w-5xl px-8 my-4">
          <p className="text-3xl text-left font-bold text-[#3c3e95]">
            Key Features
          </p>

          <ul className="list-disc list-inside text-[1.25rem] text-[#3c3e95] my-4 text-left">
            <li>Life time Admission</li>
            <li>Study material and Online test series </li>
            <li>
              Full time guidance from experienced successful banking officers
            </li>
            <li>Full time doubt clearance session by concerned faculties</li>
            <li>
              Preparation of group discussion and personal interview also along
              with written exam
            </li>
            <li>
              Preparation of group discussion and personal interview also along
              with written exam
            </li>
            <li>Online application processing facility</li>
            <li>
              Personal hostel and library facility available for both boys and
              girls.
            </li>
            <li>One to one attention as limited students per batch</li>
            <li>”From dream to reality" session on every second</li>
            <li>Sunday by bank officers from different banks</li>
          </ul>
        </div>

        <div className="flex flex-col md:flex-row items-stretch w-full max-w-5xl px-4">
          <div className=" p-4 flex flex-col justify-between">
            <p className="text-justify text-[1.25rem] text-[#3c3e95]">
              We are committed to ensuring that our students are well prepared
              for IBPS Bank PO, Clerk, SSC & RRB exams. We are committed to
              providing quality education and guidelines for our students with a
              view of making them capable enough to tackle their competitors in
              different competitive & entrance exams. Here at Borde's, we
              provide necessary study materials and conduct demo tests for the
              development of our students.
            </p>
          </div>
        </div>

        {/* team */}
        <div className="w-full max-w-5xl px-8 my-4 flex justify-center">
          <div>
            <p className="text-3xl text-center font-bold text-[#3c3e95] my-[40px]">
              Meet Our Team
            </p>

            <div className="my-4 max-w-[200px] mx-auto">
              <img
                src={Bordesir}
                alt="Borde's Team"
                className="w-full max-w-[200px] h-auto rounded-xl my-2"
              />
              <p className="text-xl font-semibold text-[#3c3e95] uppercase">
                Santosh Sir
              </p>
              <hr className="my-2" />
              <p className="text-md text-[#666]">Quantitative Aptitude</p>
              <p className="text-md text-[#666]">General Intelligence</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 gap-x-[80px] gap-y-[40px] md:mt-[40px]">
              {teamData.slice(1, 7).map((member, index) => (
                <TeamMember
                  key={index}
                  name={member.name}
                  expertise={member.expertise}
                  image={member.image}
                />
              ))}
            </div>
          </div>
        </div>

        <div className=" w-full max-w-5xl px-8 my-4">
          <p className="text-3xl text-left font-bold text-[#3c3e95]">
            Director's Note :
          </p>
          <p className="text-left text-[1.25rem] text-[#3c3e95] my-4">
            "It is with immense pride and gratitude that I address you as we
            celebrate a remarkable milestone—25 years of excellence at Bordes
            Career Academy. What began as a modest endeavor to empower aspiring
            banking professionals has blossomed into a premier institution,
            shaping thousands of successful careers in the banking sector.{" "}
            <br /> Over the past quarter-century, our academy has consistently
            upheld the values of integrity, dedication, and innovation. We have
            adapted to the evolving demands of the banking industry, ensuring
            our students are not just prepared for exams but are equipped with
            the knowledge and skills to excel in their careers. <br />
            Our success is a testament to the unwavering commitment of our
            faculty, the diligence of our students, and the trust placed in us
            by parents and the broader community. As we look to the future, we
            remain steadfast in our mission to provide world-class education and
            to continue being a beacon of excellence in banking exam
            preparation. <br /> Thank you for being a part of our journey. Let
            us strive together for many more years of success."
          </p>

          <div className="w-full flex justify-end">
            <div>
              <p className="text-lg text-center font-bold text-[#3c3e95]">
                Warm Regards, <br /> PROF. SANTOSH BORDE <br /> Director,
                Borde's Career Academy
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;

const TeamMember = ({ name, expertise, image }) => (
  <div className="grid text-center">
    <img
      src={image}
      alt={`${name}'s Team`}
      className="w-full max-w-[200px] h-[200px] object-cover rounded-xl my-2"
    />
    <p className="text-xl font-semibold text-[#3c3e95] capitalize">{name}</p>
    <hr className="my-2" />
    {expertise.map((skill, index) => (
      <p key={index} className="text-md text-[#666]">
        {skill}
      </p>
    ))}
  </div>
);
