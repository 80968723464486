import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./navbar.module.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.navbar}>
      <div className={styles.container}>
        <div className="flex items-center gap-4 md:gap-8">
          <Link to={"/"}>
            <img
              className="w-[70px] h-[70px] md:w-[90px] md:h-[90px] "
              src="https://i.ibb.co/z7F83FY/Borde-Logo.png"
              alt="logo"
            />
          </Link>
          <div>
            <p className="">
              <span className={styles.titlered}>बोर्डेज एज्युकेशन सोसायटी</span>
              <br className="m-0 p-0" />
              <span className={styles.titleBlack}>संचालित</span>
              <br className="m-0 p-0" />
              <span className={styles.titleBlue}>बोर्डेज करिअर अकॅडमी</span>
            </p>
          </div>
        </div>
        <div className="flex items-center gap-4 ">
          <div className={styles.hamburger} onClick={toggleMenu}>
            {isOpen ? (
              <span className={styles.closeButton}>&#10005;</span>
            ) : (
              <span className={styles.hamburgerButton}>&#9776;</span>
            )}
          </div>

          <Link to="/" className={styles.navItem}>
            Home
          </Link>
          <Link to="/about" className={styles.navItem}>
            About Us
          </Link>
          <Link to="/why-us" className={styles.navItem}>
            Why Us
          </Link>
          <Link to="/testimonials" className={styles.navItem}>
            Success Stories
          </Link>
          <Link to="/success-stories" className={styles.navItem}>
            Result
          </Link>
          <Link to="/contact" className={styles.navItem}>
            Contact Us
          </Link>
        </div>
      </div>

      <div
        className={`${styles.sidebar} ${isOpen ? styles.open : ""}`}
        onClick={toggleMenu}
      >
        <Link to="/" className={styles.navItem}>
          Home
        </Link>
        <Link to="/about" className={styles.navItem}>
          About Us
        </Link>
        <Link to="/why-us" className={styles.navItem}>
          Why Us
        </Link>
        <Link to="/testimonials" className={styles.navItem}>
          Success Stories
        </Link>
        <Link to="/success-stories" className={styles.navItem}>
          Result
        </Link>
        <Link to="/contact" className={styles.navItem}>
          Contact Us
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
